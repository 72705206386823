const dominioPort = window.location.host
const dominio = dominioPort

const env = {
  apiUrl: window.location.protocol + '//' + dominio
}

if (dominio === 'localhost:8080') {
  env.apiUrl = 'http://localhost:5000'
}

export default env
