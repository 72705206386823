//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import crearArticulo from '@/components/crearArticulo.vue'
import editArticulo from '@/components/editArticulo.vue'
import { exportFile } from 'quasar'

function wrapCsvValue (val, formatFn) {
  let formatted = formatFn !== undefined
    ? formatFn(val)
    : val

  formatted = formatted === undefined || formatted === null
    ? ''
    : String(formatted)

  formatted = formatted.split('"').join('""')
  return `"${formatted}"`
}

export default {
  components: {
    crearArticulo,
    editArticulo
  },
  data () {
    return {
      editArticulo: {},
      dialogEdit: false,
      dialogNuevo: false,
      loadingTable: false,
      articulos: [],
      filter: '',
      pagination: {
        rowsPerPage: 0
      },
      columns: [{
        name: 'fechaCreacion',
        align: 'center',
        label: 'Fecha de creación',
        field: row => row.dateCreated,
        format: val => this.$fecha(val),
        sortable: true
      }, {
        name: 'nombre',
        align: 'left',
        label: 'Nombre',
        field: row => row.nombre,
        sortable: true
      }, {
        name: 'desc',
        align: 'left',
        label: 'Descripccion',
        field: row => row.descripcion,
        sortable: true
      }, {
        name: 'precioProv',
        align: 'center',
        label: 'Precio Proveedor',
        field: row => row.precios.Proveedor,
        format: (val, row) => this.$options.filters.currency(val),
        sortable: true
      }, {
        name: 'precioPub',
        align: 'center',
        label: 'Precio Publico',
        field: row => row.precios.Publico,
        format: (val, row) => this.$options.filters.currency(val),
        sortable: true
      }, {
        name: 'administracion',
        align: 'center',
        label: 'Administración'
      }]
    }
  },
  props: {
  },
  methods: {
    exportTable () {
      const content = [this.$refs.tabla.columns.map(col => wrapCsvValue(col.label))].concat(
        this.$refs.tabla.data.map(row => this.$refs.tabla.columns.map(col => wrapCsvValue(
          typeof col.field === 'function'
            ? col.field(row)
            : row[col.field === undefined ? col.name : col.field],
          col.format
        )).join(','))
      ).join('\r\n')

      const status = exportFile(
        'articulos.csv',
        content,
        'text/csv'
      )

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning'
        })
      }
    },
    putArticulo (data) {
      const index = this.articulos.findIndex(elmento => {
        return elmento._id === data._id
      })
      this.articulos[index] = data
      this.dialogEdit = true
    },
    editar (data) {
      this.editArticulo = data
      this.dialogEdit = true
    },
    async eliminar (data) {
      this.$confirm('¿Desea eliminar el articulo: ' + data.nombre + '?', 'Warning', {
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(async _ => {
        const articulo = await this.$api.delete('/api/articulo/' + data._id)
          .then(response => {
            return response.data
          })
          .catch(error => {
            console.log(error)
            return error
          })

        if (articulo && !articulo.error) {
          this.loadingTable = true
          await this.cargar()
          this.loadingTable = false
        }
      }).catch(_ => {})
    },
    nuevo (data) {
      if (data && !data.error) {
        this.articulos.push(data)
      } else {
        console.log(data)
      }
    },
    async cargar () {
      const data = await this.$api.get('/api/articulos').then(response => {
        return response.data
      }).catch(error => {
        console.log(error)
        return error
      })
      if (data && !data.error) {
        this.articulos = data
      }
    }
  },
  created () {
    this.cargar()
  },
  watch: {
  }
}
