//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import crearCliente from '@/components/crearCliente.vue'
import crearArticulo from '@/components/crearArticulo.vue'

let clientes = []
let articulos = []

export default {
  components: {
    crearCliente,
    crearArticulo
  },
  data () {
    return {
      total: 0,
      pagination: {
        rowsPerPage: 0
      },
      columns: [{
        name: 'nombre',
        label: 'Nombre',
        field: 'nombre',
        align: 'right'
      }, {
        name: 'cantidad',
        label: 'Cantidad',
        field: 'cantidad'
      }, {
        name: 'dias',
        label: 'Dias',
        field: 'dias'
      }, {
        name: 'precioUnitario',
        label: 'Precio Unitario',
        field: row => {
          let precio = 0
          for (let i = row.descDiaP.length - 1; i >= 0; i--) {
            precio += row.precios[this.cliente.tipoPrecio] - (row.precios[this.cliente.tipoPrecio] * row.descDiaP[i] / 100)
          }
          return precio
        },
        format: val => `${val}`,
        align: 'right'
      }, {
        name: 'total',
        label: 'Total',
        field: row => {
          let precio = 0
          for (let i = row.descDiaP.length - 1; i >= 0; i--) {
            precio += row.precios[this.cliente.tipoPrecio] - (row.precios[this.cliente.tipoPrecio] * row.descDiaP[i] / 100)
          }
          precio = precio * row.cantidad
          return precio
        },
        format: val => `${val}`,
        align: 'right'
      }],
      dialogArticulos: false,
      articuloSelect: '',
      articulosAdd: [],
      articulos: [],
      dialogClientes: false,
      clientes: [],
      cliente: '',
      pickerOptions: {
        disabledDate (time) {
          const fecha = new Date()
          fecha.setDate(fecha.getDate() - 1)
          return time < fecha
        },
        firstDayOfWeek: 1
      },
      servicio: {
        dateServicio: new Date(),
        cliente: '',
        nombre: '',
        apellidos: '',
        telefono: '',
        calle: '',
        numE: '',
        numI: '',
        cp: '',
        colonia: '',
        referencia: '',
        ciudad: '',
        estado: '',
        tipoPrecio: '',
        incluirIva: false,
        articulos: []
      }
    }
  },
  methods: {
    nuevoArticulo (articulo) {
      this.articulos.push(articulo)
    },
    nuevoCliente (cliente) {
      this.clientes.push(cliente)
    },
    calcDescuentoP (articulo, index) {
      articulo.descDiaP[index] = 100 * articulo.descDiaM[index] / articulo.precios[this.cliente.tipoPrecio]
    },
    calcDescuentoM (articulo, index) {
      articulo.descDiaM[index] = articulo.descDiaP[index] / 100 * articulo.precios[this.cliente.tipoPrecio]
    },
    async saveNota () {
      this.servicio.articulos = []
      for (let i = 0; i < this.articulosAdd.length; i++) {
        const articulo = {
          articulo: this.articulosAdd[i].articulo,
          precio: this.articulosAdd[i].precios[this.cliente.tipoPrecio],
          descDiaP: this.articulosAdd[i].descDiaP,
          descDiaM: this.articulosAdd[i].descDiaM,
          dias: this.articulosAdd[i].dias,
          cantidad: this.articulosAdd[i].cantidad
        }
        this.servicio.articulos.push(articulo)
      }

      const servicio = await this.$api.post('/api/servicio', this.servicio)
        .then(response => {
          if (response.data.error) {
            console.error(response.data)
          }
          return response.data
        })
        .catch(error => {
          console.error(error)
          return error
        })

      if (!servicio.error) {
        const blob = await this.$api.get('/api/servicio/nota/' + servicio._id, {
          responseType: 'blob'
        })
          .then(response => {
            return response.data
          })
          .catch(error => {
            console.error(error)
            return error
          })

        const filename = 'Servicio_' + servicio.folio + '.pdf'
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(blob, filename)
        } else {
          const blobURL = window.URL.createObjectURL(blob)
          const tempLink = document.createElement('a')
          tempLink.style.display = 'none'
          tempLink.href = blobURL
          tempLink.setAttribute('download', filename)

          if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank')
          }
          document.body.appendChild(tempLink)
          tempLink.click()
          document.body.removeChild(tempLink)
          setTimeout(() => {
            window.URL.revokeObjectURL(blobURL)
          }, 100)
        }
      } else {
        const mensaje = servicio.message || 'Ocurrio un error al crear el servicio, por favor comunicate con soporte o intentelo mas tarde.'
        this.$alert(
          mensaje,
          'Error al Crear el Servicio',
          {
            confirmButtonText: 'OK',
            type: 'error'
          }
        )
      }
    },
    calcTotal () {
      let total = 0
      for (let i = 0; i < this.articulosAdd.length; i++) {
        let subTotal = 0
        for (let j = 0; j < this.articulosAdd[i].dias; j++) {
          subTotal += (this.articulosAdd[i].precios[this.cliente.tipoPrecio] - this.articulosAdd[i].descDiaM[j])
        }
        total += subTotal * this.articulosAdd[i].cantidad
      }
      if (this.servicio.incluirIva) {
        total = total * 1.16
      }
      this.total = total
    },
    llenarArray (datos) {
      datos.descDiaP = []
      datos.descDiaM = []
      for (let i = 0; i < datos.dias; i++) {
        datos.descDiaP[i] = 0
        datos.descDiaM[i] = 0
      }
    },
    addArticulo () {
      if (this.articuloSelect !== '') {
        const articulo = {
          articulo: this.articuloSelect._id,
          nombre: this.articuloSelect.nombre,
          cantidad: 1,
          dias: 1,
          descDiaP: [0],
          descDiaM: [0],
          precios: this.articuloSelect.precios,
          expand: false
        }
        this.articulosAdd.push(articulo)
        this.articuloSelect = ''
        this.calcTotal()
      }
    },
    cerrarArticulos () {
      articulos = this.articulos
      this.dialogArticulos = false
    },
    transfData () {
      this.servicio.cliente = this.cliente._id
      this.servicio = Object.assign(this.servicio, this.cliente)
    },
    cerrarClientes () {
      clientes = this.clientes
      this.dialogClientes = false
    },
    filterArticulo (val, update) {
      if (val === '') {
        update(() => {
          this.clientes = clientes
        })
      } else {
        update(() => {
          const needle = val.toLowerCase()
          this.clientes = clientes.filter(v => {
            const text = v.nombre + ' ' + v.apellidos
            return text.toLowerCase().indexOf(needle) > -1
          })
        })
      }
    },
    filterCliente (val, update) {
      if (val === '') {
        update(() => {
          this.clientes = clientes
        })
      } else {
        update(() => {
          const needle = val.toLowerCase()
          this.clientes = clientes.filter(v => {
            const text = v.nombre + ' ' + v.apellidos
            return text.toLowerCase().indexOf(needle) > -1
          })
        })
      }
    }
  },
  async created () {
    this.clientes = await this.$api.get('/api/clientes')
      .then(response => {
        if (response.data.error) {
          console.error(response.data)
        } else {
          clientes = response.data
        }
        return clientes
      })
      .catch(error => {
        console.error(error)
        return []
      })

    this.articulos = await this.$api.get('/api/articulos')
      .then(response => {
        if (response.data.error) {
          console.error(response.data)
        } else {
          articulos = response.data
        }
        return articulos
      })
      .catch(error => {
        console.error(error)
        return []
      })
  }
}
