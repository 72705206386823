import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './quasar'

// Dependencias Externas
import env from './env'
import axios from 'axios'
import moment from 'moment'
import Vue2Filters from 'vue2-filters'
import Transitions from 'vue2-transitions'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/es'

Vue.config.productionTip = false

// Funciones locales
const filtroFecha = val => {
  if (!val) return ''
  const fecha = moment(new Date(val)).format('L') + ' ' + moment(new Date(val)).format('LTS')
  return fecha
}

// Filtros
Vue.filter('fecha', filtroFecha)

// Prototypes
Vue.prototype.$load = activo => {
  Vue.prototype.$loader = activo
}
Vue.prototype.$loader = false
Vue.prototype.$axios = axios
Vue.prototype.$api = axios.create({
  baseURL: env.apiUrl
})
Vue.prototype.$ruleRquerido = [val => (val !== undefined && val !== '' && val !== null) || 'Este dato es necesario']
Vue.prototype.$fecha = filtroFecha

Vue.prototype.$api.interceptors.request.use(function (config) {
  Vue.prototype.$loader = true
  return config
}, function (error) {
  Vue.prototype.$loader = false
  return Promise.reject(error)
})

Vue.prototype.$api.interceptors.response.use(function (response) {
  Vue.prototype.$loader = false
  return response
}, function (error) {
  Vue.prototype.$loader = false
  return Promise.reject(error)
})

// Importacion de Librerias
Vue.use(Vue2Filters)
Vue.use(Transitions)
Vue.use(ElementUI, { locale })

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
