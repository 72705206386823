//
//
//
//
//
//
//

export default {
  name: 'NotFound',
  created: _ => {
    console.log('404')
  }
}
