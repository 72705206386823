//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'crear-cliente',
  data () {
    return {
      cliente: {
        nombre: '',
        apellidos: '',
        nomEmpresa: '',
        celular: '',
        email: '',
        telefono: '',
        calle: '',
        numE: '',
        numI: '',
        colonia: '',
        referencia: '',
        ciudad: '',
        estado: '',
        tipoPrecio: ''
      }
    }
  },
  methods: {
    async save () {
      const cliente = await this.$api.post('/api/cliente', this.cliente)
        .then(response => {
          if (response.data.error) {
            console.error(response.data)
          }
          return response.data
        })
        .catch(error => {
          console.error(error)
          return error
        })

      this.$emit('saved', cliente)
      this.$emit('cancel')
    }
  }
}
