//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      render: false,
      leftDrawerOpen: false
    }
  },
  methods: {
    toggleLeftDrawer () {
      this.leftDrawerOpen = !this.leftDrawerOpen
    },
    salir () {
      sessionStorage.clear()
      localStorage.clear()
      this.$router.replace('/')
    }
  },
  async created () {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token')
    if (token) {
      const usuario = await this.$api.post('/api/usuario/login/token', { token: token }).then(response => {
        if (response.data.error) {
          console.error(response.data)
        }
        return response.data
      }).catch((error) => {
        error.error = true
        console.error(error)
        return error
      })

      if (usuario.error) {
        this.salir()
      } else {
        if (usuario.rol !== this.$route.matched[0].props.default.auth) {
          this.salir()
        } else {
          this.$api.defaults.headers.common.Authorization = 'Bearer ' + token
          sessionStorage.setItem('token', token)
          localStorage.setItem('token', token)
          this.render = true
        }
      }
    } else {
      this.$router.replace('/')
    }
  }
}
