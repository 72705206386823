//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import crearCliente from '@/components/crearCliente.vue'
import editCliente from '@/components/editCliente.vue'
import { exportFile } from 'quasar'

function wrapCsvValue (val, formatFn) {
  let formatted = formatFn !== undefined
    ? formatFn(val)
    : val

  formatted = formatted === undefined || formatted === null
    ? ''
    : String(formatted)

  formatted = formatted.split('"').join('""')
  return `"${formatted}"`
}

export default {
  components: {
    crearCliente,
    editCliente
  },
  data () {
    return {
      editCliente: {},
      dialogEdit: false,
      dialogNuevo: false,
      loadingTable: false,
      clientes: [],
      filter: '',
      pagination: {
        rowsPerPage: 0
      },
      columns: [{
        name: 'fechaCreacion',
        align: 'center',
        label: 'Fecha de creación',
        field: row => row.dateCreated,
        format: val => this.$fecha(val),
        sortable: true
      }, {
        name: 'nombre',
        align: 'center',
        label: 'Nombre',
        field: row => row.nombre,
        sortable: true
      }, {
        name: 'apellidos',
        align: 'center',
        label: 'Apellidos',
        field: row => row.apellidos,
        sortable: true
      }, {
        name: 'empresa',
        align: 'center',
        label: 'Empresa',
        field: row => row.nomEmpresa,
        sortable: true
      }, {
        name: 'telefono',
        align: 'center',
        label: 'Telefono',
        field: row => row.telefono,
        sortable: true
      }, {
        name: 'celular',
        align: 'center',
        label: 'Celular',
        field: row => row.celular,
        sortable: true
      }, {
        name: 'email',
        align: 'center',
        label: 'Email',
        field: row => row.email,
        sortable: true
      }, {
        name: 'administracion',
        align: 'center',
        label: 'Administración'
      }]
    }
  },
  props: {
  },
  methods: {
    exportTable () {
      const content = [this.$refs.tabla.columns.map(col => wrapCsvValue(col.label))].concat(
        this.$refs.tabla.data.map(row => this.$refs.tabla.columns.map(col => wrapCsvValue(
          typeof col.field === 'function'
            ? col.field(row)
            : row[col.field === undefined ? col.name : col.field],
          col.format
        )).join(','))
      ).join('\r\n')

      const status = exportFile(
        'clientes.csv',
        content,
        'text/csv'
      )

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning'
        })
      }
    },
    putCliente (cliente) {
      const index = this.clientes.findIndex(elmento => {
        return elmento._id === cliente._id
      })
      this.clientes[index] = cliente
      this.dialogEdit = true
    },
    editar (cliente) {
      this.editCliente = cliente
      this.dialogEdit = true
    },
    async eliminar (cliente) {
      this.$confirm('¿Desea eliminar el cliente ' + cliente.nombre + ' ' + cliente.apellidos + '?', 'Warning', {
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(async _ => {
        const clientes = await this.$api.delete('/api/cliente/' + cliente._id)
          .then(response => {
            return response.data
          })
          .catch(error => {
            console.log(error)
            return error
          })

        if (clientes && !clientes.error) {
          this.loadingTable = true
          await this.cargarClientes()
          this.loadingTable = false
        }
      }).catch(_ => {})
    },
    nuevoCliente (cliente) {
      if (cliente && !cliente.error) {
        this.clientes.push(cliente)
      } else {
        console.log(cliente)
      }
    },
    async cargarClientes () {
      const clientes = await this.$api.get('/api/clientes').then(response => {
        return response.data
      }).catch(error => {
        console.log(error)
        return error
      })
      if (clientes && !clientes.error) {
        this.clientes = clientes
      }
    }
  },
  created () {
    this.cargarClientes()
  },
  watch: {
  }
}
