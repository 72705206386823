import Vue from 'vue'
import VueRouter from 'vue-router'

import layout from '../components/layout.vue'

// Vistas Publicas
import NotFound from '../vistas/public/404.vue'
import login from '../vistas/public/login.vue'

// Vistas Generales
import crearServicio from '../vistas/crearServicio.vue'
import clientes from '../vistas/clientes.vue'
import articulos from '../vistas/articulos.vue'

Vue.use(VueRouter)

const routes = [{
  path: '*',
  component: NotFound
}, {
  path: '/',
  component: login
}, {
  path: '/su',
  component: layout,
  props: { auth: 'su' },
  children: [{
    path: '',
    component: crearServicio
  }, {
    path: 'clientes',
    component: clientes
  }, {
    path: 'articulos',
    component: articulos
  }]
}]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
