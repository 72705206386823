import { render, staticRenderFns } from "./crearServicio.vue?vue&type=template&id=114545ce&"
import script from "./crearServicio.vue?vue&type=script&lang=js&"
export * from "./crearServicio.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QCard,QCardSection,QSeparator,QSelect,QItem,QItemSection,QBtn,QInput,QTable,QTr,QTh,QTd,QCheckbox,QDialog} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QSeparator,QSelect,QItem,QItemSection,QBtn,QInput,QTable,QTr,QTh,QTd,QCheckbox,QDialog})
