//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'crear-articulo',
  data () {
    return {
      articulo: {
        nombre: '',
        descripcion: '',
        precios: {
          Proveedor: '',
          Publico: ''
        }
      }
    }
  },
  methods: {
    async save () {
      const articulo = await this.$api.post('/api/articulo', this.articulo)
        .then(response => {
          if (response.data.error) {
            console.error(response.data)
          }
          return response.data
        })
        .catch(error => {
          console.error(error)
          return error
        })

      this.$emit('saved', articulo)
      this.$emit('cancel')
    }
  },
  async created () {
  }
}
