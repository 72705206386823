//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'edit-articulo',
  props: {
    articuloEdit: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      articulo: {}
    }
  },
  methods: {
    async save () {
      const articulo = await this.$api.put('/api/articulo/' + this.articulo._id, this.articulo)
        .then(response => {
          if (response.data.error) {
            console.error(response.data)
          }
          return response.data
        })
        .catch(error => {
          console.error(error)
          return error
        })

      if (!articulo.error) {
        this.$emit('saved', articulo)
      }
      this.$emit('cancel')
    }
  },
  async created () {
    this.articulo = JSON.parse(JSON.stringify(this.articuloEdit))
  }
}
