//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      usuario: {
        user: null,
        password: null
      },
      isPwd: true,
      recordarme: true
    }
  },
  methods: {
    async loguearse () {
      const playLoad = {
        user: this.usuario.user,
        password: this.usuario.password
      }
      await this.$api.post('/api/usuario/login', playLoad).then(response => {
        if (!response.data.error) {
          this.$api.defaults.headers.common.Authorization = 'Bearer ' + response.data.token
          if (this.recordarme) {
            localStorage.clear()
            sessionStorage.clear()
            localStorage.setItem('token', response.data.token)
            sessionStorage.setItem('token', response.data.token)
          } else {
            localStorage.clear()
            sessionStorage.clear()
            sessionStorage.setItem('token', response.data.token)
          }
          this.$router.replace('/' + response.data.rol)
        } else {
          sessionStorage.clear()
          localStorage.clear()
        }
      }).catch((error) => {
        sessionStorage.clear()
        localStorage.clear()
        console.log(error)
      })
    }
  },
  async created () {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token')
    if (token) {
      const usuario = await this.$api.post('/api/usuario/login/token', { token: token }).then(response => {
        if (response.data.error) {
          console.error(response.data)
        }
        return response.data
      }).catch((error) => {
        error.error = true
        console.error(error)
        return error
      })

      if (usuario.error) {
        sessionStorage.clear()
        localStorage.clear()
        this.$router.replace('/')
      } else {
        this.$api.defaults.headers.common.Authorization = 'Bearer ' + token
        sessionStorage.setItem('token', token)
        localStorage.setItem('token', token)
        this.$router.replace('/' + usuario.rol)
      }
    }
  }
}
